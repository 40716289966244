import {
  Alert,
  Avatar,
  Checkbox,
  IconButton,
  MenuItem,
  Popover,
  Snackbar,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import React, { useEffect, useState } from 'react';
import { useAccount, useWaitForTransactionReceipt, useWriteContract } from 'wagmi';
import axios from 'axios';
import { ethers } from 'ethers';

import ImageIcon from '@mui/icons-material/Image';
import { Link } from 'react-router-dom';
import Iconify from '../components/iconify';
import ViewModal from './ViewModal/ViewModal';
import { API_URL, NFT_CONTRACT, IPFS_URL, EXPLORER_URL } from '../config';
import NFT_CONTRACT_ABI from '../config/NFT_CONTRACT_ABI.json';
import Label from '../components/label';
import TransactionModal from './TransactionModal/TransactionModal';
import ImageList from './ImageList';
import CommissionModal from './CommissionModal';
import ApproveAccountModal from './ViewModal/ApproveAccountModal';
import UpdateKycModal from './UpdateKycModal';
import CancelStatusModal from './CancelStatusModal';


const GirlTableListRow = ({ row, selected, getGirlData, referredBy, setShowSnackbar,setShowSnackbarRej }) => {
  const {
    id,
    firstName,
    lastName,
    email,
    status,
    approved,
    requestForApproval,
    wallet,
    kycStatus,
    avatarUrl,
    meta_location: ipfs,
    photographer,
    username,
    images,
    infoApprove
  } = row;
  const [girlRefData, setGirlRefData] = useState([]);
  const [error, setError] = useState(false);
  const getReferredGirlData = async () => {
    await axios({
      method: 'GET',
      url: `${API_URL}/api/users?filters[user_type][$eq]=Girl${`&filters[referredBy][$eq]=${username}`}&populate=*`,
      headers: {
        'Accept-Encoding': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,

        // 'Origin' : process.env.ORIGIN
      },
    })
      .then((_data) => {
        // console.log(_data);
        setGirlRefData(_data.data);
      })
      .catch((err) => {
        //  throw err
        console.log(err);
      });
  };
  useEffect(()=>{
    getReferredGirlData()
  },[username,referredBy])
  const selectedUser = selected.indexOf(firstName) !== -1;
  const { address, isConnected } = useAccount();
  const [transactionOpen, setTransactionOpen] = useState(false);
  const [open, setOpen] = useState(null);
  const [imageModal, setImageModal] = useState(null);
  const [commissionModal, setCommissionModal] = useState(false);
  const [updateKyc, setUpdateKyc] = useState(false);
  const [statusOpen, setStatusOpen] = useState(false);
  const [createCollection, setCreateCollection] = useState(false);
  const [collectionData, setCollectionData] = useState({});
  const [approvedImages, setApprovedImages] = useState([]);

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setOpen(null);
  };

  const [view, setView] = useState(false);
  const [accountView, setAccountView] = useState(false);
  const handleClickOpen = (type) => {
    setCreateCollection(type)
    setView(true);
  };
  const handleClickClose = () => {
    setView(false);
  };
  const handleAccountModalClose = () => {
    setAccountView(false);
  };

  // const url = `${IPFS_URL}/${ipfs}`;
  const { writeContractAsync } = useWriteContract();
  const [txn, setTxn] = useState("");
  const [
    isLoading,
    setIsLoading,
  ] = useState(false);
  const Config = {
    address: NFT_CONTRACT,
    abi: NFT_CONTRACT_ABI,
    functionName: 'registerGirl',
    args: [id.toString(), Number(approvedImages?.length)],
    enabled: approvedImages !== null && approvedImages?.length && id !=null && id !==undefined
  };
  const registerGirlWrite = async () => {
    setTxn(null);
    try {
      console.log(Config);
      setIsLoading(true);
      const txn = await writeContractAsync?.(Config);
      console.log(txn);
      
      setTxn(txn);
    } catch (e) {
      setIsLoading(false);
      setError(true)
      console.log(e);
    }
  };
  const {
    isLoading: isLoadingWaitForTransaction,
    isSuccess: isSuccessWaitForTransactionIsSuccess,
    error:isError
    // data: createTokenData,
  } = useWaitForTransactionReceipt({
    hash: txn,
  });

  // console.log(waitTxnSuccess);

  // const { config: registerPhotographerConfig } = usePrepareContractWrite({
  //   address: NFT_CONTRACT,
  //   abi: NFT_CONTRACT_ABI,
  //   functionName: 'registerGirlWithPhotographer',
  //   args: [url, wallet, 1, photographer],
  //   enabled: url !== undefined && wallet !== undefined && photographer !== undefined,
  // });
  // const {
  //   data: registerPhotographerData,
  //   isLoading: registerPhotographerLoading,
  //   isSuccess: registerPhotographerSuccess,
  //   isError: registerPhotographerError,
  //   write: registerPhotographerWrite,
  // } = useContractWrite(registerPhotographerConfig);
  // const { isSuccess: waitTxnSuccessPhoto, isError: waitTxnErrorPhoto } = useWaitForTransaction({
  //   hash: registerPhotographerData?.hash,
  // });
  // // console.log(waitTxnSuccess);

  // const { config: registerAffiliateConfig } = usePrepareContractWrite({
  //   address: NFT_CONTRACT,
  //   abi: NFT_CONTRACT_ABI,
  //   functionName: 'registerWithAffilates',
  //   args: [url, wallet, 1, referredBy],
  //   enabled: url !== undefined && wallet !== undefined && referredBy !== undefined,
  // });
  // const {
  //   data: registerAffiliateData,
  //   isLoading: registerAffiliateLoading,
  //   isSuccess: registerAffiliateSuccess,
  //   isError: registerAffiliateError,
  //   write: registerAffiliateWrite,
  // } = useContractWrite(registerAffiliateConfig);
  // const { isSuccess: waitTxnSuccessAff, isError: waitTxnErrorAff } = useWaitForTransaction({
  //   hash: registerAffiliateData?.hash,
  // });

  useEffect(() => {
    if (isSuccessWaitForTransactionIsSuccess) {
      profileApproved();
    }

    if (
      isError
    ) {
      closeTransactionModal();
    }
  }, [
    isSuccessWaitForTransactionIsSuccess,
    isError
  ]);

  useEffect(() => {
    if(isLoading || isLoadingWaitForTransaction){
      openTransactionModal()
    }
  },[isLoadingWaitForTransaction,isLoading])

  // console.log(waitTxnSuccess);
  const registerGirlBtn = async () => {
   
      registerGirlWrite?.();
      // openTransactionModal();
    
  };
  const profileApproved = async () => {
    const config = {
      headers: {
        // authorization: localStorage.getItem(JWT),
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    try {
      const data = await axios.post(
        `${API_URL}/api/users/approve-user`,
        { id },
        config
      );
      // console.log(data);
      if (data.data.status === 'Approved') {
        setShowSnackbar(true);
        handleCloseMenu();
        getGirlData();
      closeTransactionModal();

        setTimeout(() => {
          setShowSnackbar(false);
        }, 2000);
        // window.location.reload();
      }
      // console.log(data);
      // setEarningData(data.data);
    } catch (error) {
      console.log('catch', error);
    }
  };
  const getGirlCollectionData = async () => {
    await axios({
      method: 'GET',
      url: `${API_URL}/api/collection/check/${id}`,
      headers: {
        'Accept-Encoding': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,

        // 'Origin' : process.env.ORIGIN
      },
    })
      .then((_data) => {

        setCollectionData(_data.data)
      })
      .catch((err) => {
        //  throw err
        console.log(err);
      });
  };
  const getAllCollection = async () => {
    await axios({
      method: 'GET',
      url: `${API_URL}/api/getSingleCollectionAdmin/${id}`,
      headers: {
        'Accept-Encoding': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,

        // 'Origin' : process.env.ORIGIN
      },
    })
      .then((_data) => {
        setApprovedImages(_data.data.collection);
      })
      .catch((err) => {
        //  throw err
        console.log(err);
      });
  };
useEffect(()=>{
  if(id){
    getAllCollection()
    getGirlCollectionData()
  }
},[id])
// console.log(collectionData);
  const profileRejected = async () => {
    const config = {
      headers: {
        // authorization: localStorage.getItem(JWT),
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    try {
      const data = await axios.post(
        `${API_URL}/api/users/reject-user`,
        { id },
        config
      );
      // console.log(data);
      if (data.data.status === 'Rejected') {
        setShowSnackbarRej(true);
        getGirlData();
        handleCloseMenu();
        setTimeout(() => {
          setShowSnackbarRej(false);
        }, 2000);
      }
      // console.log(data);
      // setEarningData(data.data);
    } catch (error) {
      console.log('catch', error);
    }
  };

  // const approve =()=>{
  //   setShowSnackbar(true);
  //   setTimeout(() => {
  //     setShowSnackbar(false);
  //   }, 2000);
  // }

  // const approve = async () => {
  //   await axios({
  //     method: 'POST',
  //     url: `${API_URL}/api/approve-girl`,
  //     headers: {
  //       'Content-Type': 'application/json',
  //       // 'Origin' : process.env.ORIGIN
  //     },
  //     data: JSON.stringify({ id }),
  //   })
  //     .then((_data) => {
  //       setShowSnackbar(true);
  //       getGirlData();
  //       handleCloseMenu();
  //       closeTransactionModal();
  //       setTimeout(() => {
  //         setShowSnackbar(false);
  //       }, 2000);
  //     })
  //     .catch((err) => {
  //       //  throw err
  //       console.log(err);
  //     });
  // };

  // const reject = async () => {
  //   await axios({
  //     method: 'POST',
  //     url: `${API_URL}/api/reject-girl`,
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     data: JSON.stringify({ id }),
  //   })
  //     .then((_data) => {
  //       setShowSnackbarRej(true);
  //       getGirlData();
  //       handleCloseMenu();
  //       setTimeout(() => {
  //         setShowSnackbarRej(false);
  //       }, 2000);
  //     })
  //     .catch((err) => {
  //       //  throw err
  //       console.log(err);
  //     });
  // };

  const openTransactionModal = () => {
    setTransactionOpen(true);
  };

  const closeTransactionModal = () => {
    setTransactionOpen(false);
  };
  const KycStatusMessage = () => {
    let message;
    let color;

    switch (kycStatus) {
      case "Pending":
        message = "Pending";
        color = "warning";
        break;
      case "KYC Under Review":
        message = "Under Review";
        color = "warning";
        break;
      case "Approved":
        message = "Approved";
        color = "success";
        break;
      case "Not Approved":
        message = "Not Approved";
        color = "error";
        break;
      default:
       message = "Pending";
        color = "warning";
        break;;
    }

    return (
    
          <Label color={color}>
           {message}
          </Label>
    
    );
  };

  return (
    <>
      <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
        <TableCell align="left">{id}</TableCell>

        <TableCell component="th" scope="row" padding="none">
          <Stack direction="row" alignItems="center" spacing={2}>
            <Avatar alt={firstName} src={avatarUrl} />
            <Typography variant="subtitle2" noWrap>
              {firstName || "NA"} {lastName} 
            </Typography>
          </Stack>
        </TableCell>

        <TableCell align="left">{email}</TableCell>
        <TableCell align="left">{username}</TableCell>

       

        {/* {ipfs ? (
          <TableCell align="left">
            <a rel="noreferrer" target="_blank" href={`${IPFS_URL}${ipfs}`}>
              {ipfs.substr(0, 4)}...{ipfs.substr(ipfs.length - 4)}
            </a>
          </TableCell>
        ) : (
          <TableCell align="left">NA</TableCell>
        )} */}
        <TableCell align="left">
           {girlRefData?.length>0?<Link to={`/dashboard/girls?referredBy=${username}`}>
             View ({girlRefData?.length})
            </Link>:"0"}
            </TableCell>
        

        <TableCell align="left">
          <Label color={status === 'Approved' ? 'success' : 'error'}>
            {status === 'Approved'
              ? 'Approved'
              : status === 'Pending'
              ? collectionData?.created && !collectionData?.published?'Creating Collection': 'Approval Requested'
              : status === 'Rejected'
              ? 'Rejected'
              : 'Profile Incomplete'
              
              }
          </Label>
        </TableCell>
        <TableCell align="left">
          <Label color={infoApprove? 'success' : 'error'}>
            {infoApprove
              ? 'Approved'
             :"Not Approved"
              }
          </Label>
        </TableCell>
        <TableCell align="left">
      {KycStatusMessage()}
        </TableCell>

        {/* <TableCell align="left">
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '5px',
              fontSize: '15px',
            }}
          >
            <ImageIcon onClick={() => setImageModal(true)} sx={{ cursor: 'pointer' }} /> (
            {images?.length > 0 ? images?.length : 0})
          </Typography>
        </TableCell> */}

        <TableCell align="right">
          <IconButton size="large" color="inherit" onClick={handleOpenMenu}>
            <Iconify icon={'eva:more-vertical-fill'} />
          </IconButton>
        </TableCell>
      </TableRow>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 200,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <>
      
           
            <MenuItem onClick={()=>setUpdateKyc(true)}>
              <Iconify icon={'ant-design:check-circle-outlined'} sx={{ mr: 2 }} />
               Update KYC
            </MenuItem>
           
       
           {(status !== 'Approved' && status === 'Pending' && !collectionData.created) && (
            <MenuItem onClick={()=>handleClickOpen(3)}>
              <Iconify icon={'ant-design:check-circle-outlined'} sx={{ mr: 2 }} />
              Create Collection
            </MenuItem>
          )}
           {(status !== 'Approved' && status === 'Pending' && infoApprove===false && !collectionData.created) && (
            <MenuItem onClick={()=>setAccountView(true)}>
              <Iconify icon={'ant-design:check-circle-outlined'} sx={{ mr: 2 }} />
              Approve Account
            </MenuItem>
          )}
          {(status !== 'Approved' && status === 'Pending' && collectionData.published &&  collectionData.created) &&  (
            <MenuItem onClick={() => registerGirlBtn()}>
              <Iconify icon={'ant-design:check-circle-outlined'} sx={{ mr: 2 }} />
              Register
            </MenuItem>
          )}
         
          {status !== 'Approved' && status === 'Pending' && (
            <MenuItem sx={{ color: 'error.main' }} onClick={()=>setStatusOpen(true)}>
              <Iconify icon={'ant-design:close-circle-outlined'} sx={{ mr: 2 }} />
              Reject
            </MenuItem>
          )}

          <MenuItem onClick={()=>handleClickOpen(0)}>
            <Iconify icon={'ant-design:check-circle-outlined'} sx={{ mr: 2 }} />
            View
          </MenuItem>
          <MenuItem onClick={()=>setCommissionModal(true)}>
            <Iconify icon={'ant-design:check-circle-outlined'} sx={{ mr: 2 }} />
            Update Commission
          </MenuItem>
        </>
   
        <ViewModal open={view} handleClose={handleClickClose} viewData={row} images={images} createCollection={createCollection} collectionData={collectionData} getGirlCollectionData={getGirlCollectionData} approvedImages={approvedImages} getGirlData={getGirlData}/>
        <ApproveAccountModal open={accountView} handleClose={handleAccountModalClose} viewData={row} getGirlData={getGirlData}/>
      </Popover>
      <ImageList setImageModal={setImageModal} imageModal={imageModal} images={images} />
      <TransactionModal error={error} handleClose={closeTransactionModal} isLoading={isLoading} open={transactionOpen} />
      <CommissionModal open={commissionModal} handleClose={()=>setCommissionModal(false)} viewData={row} getGirlData={getGirlData}/>
      <UpdateKycModal open={updateKyc} handleClose={()=>setUpdateKyc(false)} viewData={row} getGirlData={getGirlData} handleCloseMenu={handleCloseMenu}/>
      <CancelStatusModal statusOpen={statusOpen} setStatusOpen={setStatusOpen} viewData={row} getGirlData={getGirlData} handleCloseMenu={handleCloseMenu}/>
    </>
  );
};

export default GirlTableListRow;
