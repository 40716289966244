import React from 'react';
import ReactDOM from 'react-dom/client';
import { Buffer } from 'buffer';

import '@rainbow-me/rainbowkit/styles.css';

// import zkicon from '../public/favicon/favicon-32x32.png'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { RainbowKitProvider, getDefaultConfig, darkTheme, connectorsForWallets, getWalletConnectConnector } from '@rainbow-me/rainbowkit';

import { WagmiProvider, createConfig, createConnector, http } from 'wagmi';
import {
  rainbowWallet,
  walletConnectWallet,
  phantomWallet,
  trustWallet,
  metaMaskWallet,
} from '@rainbow-me/rainbowkit/wallets';
import {
  polygon
} from 'wagmi/chains';

import * as serviceWorker from './serviceWorker';
import App from './App';
import reportWebVitals from './reportWebVitals';

// ----------------------------------------------------------------------


// const polygon = {
//   id: 137,
//   name: 'Polygon',
//   nativeCurrency: {
//     name: 'POL', symbol: 'POL', decimals: 18 
//   },
//   rpcUrls: {
//     default: 'https://1rpc.io/matic ',
//     // 'https://polygon-rpc.com'
//   },
//   // iconUrl: zkicon,
//   blockExplorers: {
//     default: { name: 'PolygonScan', url: 'https://polygonscan.com/',apiUrl: 'https://api.polygonscan.com/api', },
//   },
//   contracts: {
//     multicall3: {
//       address: '0xca11bde05977b3631167028862be2a173976ca11',
//       blockCreated: 25770160,
//     },
//   },

// };
const Mumbai = {
  id: 80001,
  name: 'Mumbai',
  network: 'Mumbai Testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'MATIC',
    symbol: 'MATIC',
  },
  rpcUrls: {
    default: 'https://polygon-mumbai-bor.publicnode.com	',
    // default: 'https://rpc-mumbai.maticvigil.com',
  },
  blockExplorers: {
    default: { name: 'Polygonscan', url: 'https://mumbai.polygonscan.com/' },
  },
  testnet: true,
};
const projectId = process.env.REACT_APP_WALLETCONNECT_ID
const connectors = connectorsForWallets(
  [
    {
      groupName: 'Recommended',
      wallets: [metaMaskWallet, walletConnectWallet, phantomWallet, trustWallet],
    },
  ],
  {
    appName: 'Dream-girl | Admin',
    projectId,    
  },
 
);




// const config = createConfig({
//   chains: [polygon],
//   connectors,
//   transports: {
//     [polygon.id]: http(),
//   },
// });
const config = getDefaultConfig({
  appName: 'Dream-girl | Admin',
  projectId,
  chains: [polygon],
  
});
// polyfill Buffer for client
// if (!window.Buffer) {
//   window.Buffer = Buffer;
// }

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider modalSize="compact">
          <App />
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  </React.StrictMode>
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
